/* Home page */

import React from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../components/PageLayout';
import PageHead from '../components/PageHead';
import { setLinks, getCookie, css } from '../util/pagetools';
import { localizer } from '../util/localizer';
import VolunteerForm from '../components/VolunteerForm';

export default function VolunteerPage(props) {
  const ctx = props.pageContext;
  const sitedata = props.data.volunteerdata;
  sitedata.language = ctx.locale;
  const listdata = props.data.listdata.edges[0].node;
  listdata.yearlist = ctx.yearlist;
  const endpoint = '/.netlify/functions/screenerResults';

  const toplinks = setLinks(sitedata.pagelinks);
  const sitename = sitedata.sitename;
  const siteurl = sitedata.siteurl;
  const language = ctx.locale;
  const footer = { ...sitedata.footer, toplinks, sitename, siteurl };
  const localized = localizer(ctx);

  function collector(payload) {
    const volunteerData = Object.assign({
      PlanId: sitedata.pid || 20322,
      Language: sitedata.locale,
      Url: sitedata.siteurl,
      Data1: getCookie('UTM_INFO'),
      Data2: 'ClinicalStudyFinder',
      IsEligible: 1,
      SRCode: 'PD2'
    }, mapFields(payload, {interestedIn:'ConditionList', confirm:'VolunteerCanContact'}));
    fetch(endpoint, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify(volunteerData)
    }).then(resp => { return resp.json(); }).catch(error => {
      navigate(localized('/generalError/'), {state: {description: error.message}});
    });
  }

  function mapFields(obj, lookup) {
    return Object.keys(obj).reduce((target, field) => {
      target[(lookup[field] || field)] = obj[field];
      return target;
      }, {});
  }

  return (
    <Layout sitename={sitename} links={toplinks} footer={footer} locfn={localized} theme="container-fluid">
      <PageHead lang={ctx.locale} sitename={sitename} siteurl={siteurl} meta={sitedata.metaDescription} />
      <div className="row">
        <div className="col-sm-8 col-sm-offset-2">
          <h1 id="page-title" className="text-pary-dark">Become a clinical trial volunteer</h1>
          <p>Without volunteers, there is no research. Without research, medical science does not advance to prevent diseases.</p>
          <hr />
        </div>
      </div>
      <VolunteerForm
        fields={sitedata.form.fields}
        onSubmit={collector}
        listdata={listdata}
        submitBtnLabel={sitedata.form.btnLabel} />
    </Layout>
  );
};

export const query = graphql`
  query volunteerQ($locale: String, $variation: String) {
    volunteerdata: volunteerYaml(locale: {eq: $locale}, variation: {eq: $variation}) {
      locale
      variation
      pid
      sitename
      siteurl
      title
      metaDescription
      homelink
      startstudy
      pagelinks {
        title
        linkto
      }
      sections {
        id
        linkto
        title
        theme
        data {
          content
          items
        }
        text
      }
      footer {
        links {
          label
          to
          newtab
        }
        text
        theme
      }
      form {
        btnLabel
        fields {
          firstname
          lastname
          phone
          email
          address
          gender
          birthyear
          race
          ethnicity
          interestedIn
          confirmation
        }
      }
      errorpage {
        header
        subheader
        appError
      }
    }
    listdata: allDataYaml {
      edges {
        node {
          conditions {
            id
            name
          }
          gender {
            id
            value
          }
          race
          ethnicity
        }
      }
    }
  }`;
