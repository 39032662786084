/**
 * VolunteerForm collects data for a volunteer.
 * Supported props: fields onSubmit listdata submitBtnLabel
 */
import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import InputMask from 'react-input-mask';
import Autocomplete from 'react-google-autocomplete';
import ConditionField from './ConditionField';
import Dropdown from './Dropdown';
import Checkbox from './CheckboxValidated';
import { getCookie, css } from '../util/pagetools';
import extractAddressFields from '../util/placetools';
import validator from '../util/validationManager';

const reqIndicator = <span title="This field is required.">*</span>;
// Below is temp fix for google-autocomplete
if (typeof window !== 'undefined') { (window._gmcb = () => {}); }
let formInteracted = false;

export default function VolunteerForm(props) {
  const btnTheme = 'std-btn btn-light';
  const errorTheme = props.errortheme || 'text-red';
  const COUNTRY_LOOKUPS = ['us'];
  const raceOptions = props.listdata.race.map(item => ({value:item, label:item}));;
  const ethnicityOptions = props.listdata.ethnicity.map(item => ({value:item, label:item}));;
  const vm = validator();

  const [volunteerInfo, setVolunteerInfo] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    gender: '',
    birthyear: '',
    race: '',
    ethnicity: '',
    interestedIn: ''
  });

  const [errors, setErrors] = useState({
    firstname: true,
    lastname: true,
    phone: true,
    email: true,
    autocomplete: true,
    gender: true,
    birthyear: true
  });
  const [userAddress, setUserAddress] = useState({});

  vm.addValidation('firstname', /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('lastname',  /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('phone', /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, useRef());
  vm.addValidation('email', /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/, useRef(null));
  vm.addValidation('autocomplete', /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('gender', /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('birthyear', /(.|\s)*\S(.|\s)*/, useRef(null));
  vm.addValidation('confirm', null, useRef(null));

  function onSubmit(e) {
    e.preventDefault();
    formInteracted = true;
    // Validate here!
    const inValidFields = validateForm();
    if (inValidFields.length>0) {
      // Focus on first invalid field
      const firstError = vm.getRef(inValidFields[0]).current;
      if (!firstError) return;
      else if (firstError.focus) firstError.focus();
      else if (firstError.getInputDOMNode) firstError.getInputDOMNode().focus();
      return;
    }
    if (props.onSubmit) {
      props.onSubmit(Object.assign({}, volunteerInfo, userAddress));
      formInteracted = false; // reset interaction flag
      navigate("/thank-you/");
    }
  }

  function validateForm() {
    // Collect an array of field names and their statuses
    const updatedErrors = vm.fields().reduce((status, field) => {
      const fieldvalue = (field==='autocomplete' ? (userAddress.full || '') : volunteerInfo[field]);
      const validator = vm.getRule(field);
      const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
      status[field] = !isFieldOk;
      return status;
      }, {});
    setErrors(updatedErrors);
    // Return only those items with errors
    return Object.keys(updatedErrors).filter(key => updatedErrors[key]);
  }

  function onConditionSelected(choices) {
    setVolunteerInfo({...volunteerInfo, interestedIn: choices.map(item => item.value)});
  }

  function handleInputChange(event) {
    setVolunteerInfo({
      ...volunteerInfo,
      [event.target.name]: event.target.value
    });
  }

  function storeCkboxChange(setting) {
    setVolunteerInfo({...volunteerInfo, 'confirm': setting});
    setErrors({ ...errors, 'confirm': !setting });
  }

  function validateInput(event) {
    setVolunteerInfo({
      ...volunteerInfo,
      [event.target.name]: event.target.value
    });
    const validator = vm.getRule(event.target.name);
    const isFieldOk = (validator ? validator.test(event.target.value) : true);
    setErrors({ ...errors, [event.target.name]: !isFieldOk });
  }

  function onPlaceChange(place, inputRef) {
    if (place.geometry) {
      setUserAddress(extractAddressFields(place));
    } else {
      // User entered the name of a Place that was not suggested and pressed ENTER, or the Place Details request failed.
      inputRef.value = '';
      setUserAddress({});
    }
  }

  // Runs onChange and onBlur for address field
  function validatePlaceInput(event) {
    const isFieldOk = vm.getRule('autocomplete').test(event.target.value);
    setErrors({ ...errors, 'autocomplete': !isFieldOk });
    if (!isFieldOk) { setUserAddress({}); }
  }

  function showError(field) { return (errors[field] && formInteracted); }

  /**
   * Convenience function to render a text input field.
   * @param string - a field name
   * @param function - a function to be assigned to onChange and onBlur
   * @param string - Any CSS class names
   * @param string - A validation error message; if null then NOT a required field
   * @return JSX
   */
  function makeInput(name, onChangeBlur, styling, validationMessage) {
    return (
      <div className="col-sm-6 form-group">
        <label htmlFor={name}>{props.fields[name]}{validationMessage ? <span title="This field is required.">*</span> : null}</label>
        <input type="text" name={name} id={name} className={css('form-control', styling)} aria-required="true"
               onChange={onChangeBlur} onBlur={onChangeBlur} value={volunteerInfo[name]} ref={vm.getRef(name)} />

        <p className={css(errorTheme, (showError(name)?'vis':'hid'))}>{validationMessage}</p>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-sm-8 col-sm-offset-2">
        <form className="mb-20">
          <div className="row">
            {makeInput('firstname', validateInput, null, 'First name must not be blank')}
            {makeInput('lastname', validateInput, null, 'Last name must not be blank')}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="phone">{props.fields.phone} {reqIndicator}</label>
              <PhoneInput name="phone" onChange={validateInput} value={volunteerInfo.phone} ref={vm.getRef('phone')} />
              <p className={`${errorTheme} ${showError('phone') ? 'vis' : 'hid'}`}>Please enter a valid phone number</p>
            </div>
            {makeInput('email', validateInput, null, 'Please enter a valid email')}
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="gender">{props.fields.gender} {reqIndicator}</label>
              <Dropdown name="gender"
                defaultItem="Please select gender"
                items={props.listdata.gender}
                theme="dropdown form-control"
                onChangeBlur={validateInput}
                focusRef={vm.getRef('gender')}
                errorFn={showError}
                errorMessage="Gender must not be blank" />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="birthyear">{props.fields.birthyear} {reqIndicator}</label>
              <Dropdown name="birthyear"
                defaultItem="Please select birth year"
                items={props.listdata.yearlist}
                theme="dropdown form-control"
                onChangeBlur={validateInput}
                focusRef={vm.getRef('birthyear')}
                errorFn={showError}
                errorMessage="Birth Year must not be blank" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 form-group">
              <label htmlFor="race">{props.fields.race}</label>
              <Dropdown name="race"
                defaultItem="Please select race (optional)"
                items={raceOptions}
                theme="dropdown form-control"
                onChangeBlur={handleInputChange} />
            </div>
            <div className="col-sm-6 form-group">
              <label htmlFor="ethnicity">{props.fields.ethnicity}</label>
              <Dropdown name="ethnicity"
                defaultItem="Please select ethnicity (optional)"
                items={ethnicityOptions}
                theme="dropdown form-control"
                onChangeBlur={handleInputChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="autocomplete">{props.fields.address} {reqIndicator}</label>
              <Autocomplete name="autocomplete"
                apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}
                options={{ types: ['address'], componentRestrictions: { country: COUNTRY_LOOKUPS } }}
                className="form-control"
                id="autocomplete"
                aria-required="true"
                onPlaceSelected={onPlaceChange}
                onChange={validatePlaceInput}
                onBlur={validatePlaceInput}
                ref={vm.getRef('autocomplete')} />
              <p className={`${errorTheme} ${showError('autocomplete') ? 'vis' : 'hid'}`}>Please select a valid address</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <label htmlFor="interestedIn">{props.fields.interestedIn}</label>
              <ConditionField name="interestedIn" isMulti={true} dataset={props.listdata.conditions} onChange={onConditionSelected} />
            </div>
          </div>
          <div className="row form-btn-desc">
            <div className="col-sm-8 col-sm-offset-4">
              <Checkbox name="confirm"
                        label={props.fields.confirmation}
                        onChange={storeCkboxChange}
                        focusRef={vm.getRef('confirm')}
                        errorFn={showError}
                        errorMessage="Your consent is required for processing of data." />
            </div>
          </div>
          <div className="clearfix">
            <div className="btn-container">
              <button className={btnTheme} onClick={onSubmit}>{props.submitBtnLabel}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

// Create a forward ref to enable accessing ref for focusing
const PhoneInput = React.forwardRef((props, ref) => (
  <InputMask
    mask="(999) 999-9999"
    name={props.name}
    id={props.name}
    value={props.value}
    className="form-control"
    aria-required="true"
    ref={ref}
    onChange={props.onChange}
    onBlur={props.onChange}>
  </InputMask>
));
