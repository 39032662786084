/**
 * Render a check box.
 * Supported props: name label onChange focusRef theme errorFn errorMessage
 */
import React from 'react';

export default function CheckboxValidated(props) {
  const [confirmed, setConfirmed] = React.useState(false);

  function handleChange(e) {
    const currvalue = !confirmed;
    props.onChange(currvalue);
    setConfirmed(currvalue)
  }

  const attribs = {
    type: 'checkbox',
    name: props.name,
    id: props.name,
    checked: confirmed
  };
  if (props.theme) attribs.className = props.theme;
  if (props.focusRef) attribs.ref = props.focusRef

  return (
    <div id={`container-${props.name}`} className="checkbox-validated">
      <label htmlFor={props.name}>
        <input {...attribs} onChange={handleChange} />
        <span className="pl-15">{props.label}</span>
      </label>
      {props.errorFn &&
        <p className={`text-red mb-12 ${props.errorFn(props.name) ? 'vis' : 'hid'}`}>{props.errorMessage || 'This field is required'}</p>
      }
    </div>
  );
};
